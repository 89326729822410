import React from "react";

import { Popover, Transition } from "@headlessui/react";

import "@css/pages/connexion/master.css";

import { SelectLangue } from "@components/input/index";

import { Languages } from "@helpers/_configs";
import { defaultUserImage } from "@helpers/_constants";

import { useTranslation } from "react-i18next";
import { useEffectOnceWhen } from "rooks";

// @property {import("i18next").i18n} i18n
/**
 * @typedef {Object} Props
 * @property {(e: any) => void} onClickLanguage
 * @property {(e: any) => void} onChangeLanguage
 * @property {string=} parentClass
 * @property {string=} classButton
 * @property {string=} classNameParent
 * @property {object=}selectedLanguage
 */

/**
 *
 * @param {Props} props
 * @returns
 */
function LanguageNav(props) {
  const { i18n } = useTranslation();
  const {
    // i18n,
    onClickLanguage,
    onChangeLanguage,
    parentClass = "",
    classButton = "",
    classNameParent = "",
    selectedLanguage = {}
  } = props;

  useEffectOnceWhen(() => {
    let selectedOption = Languages.find(item => item._id === i18n.language);
    onChangeLanguage(selectedOption);
  }, i18n.language === "en" && Boolean(selectedLanguage?._id) && i18n.language !== selectedLanguage._id);

  return (
    <div className={`arh-w-[5.438rem] mobile-768:arh-w-16 ${parentClass}`}>
      <Popover>
        {({ open, close }) => (
          <>
            <Popover.Button className="arh-flex arh-flex-col" as="div">
              <SelectLangue
                type="text"
                onClick={onClickLanguage}
                className={`arh-border-none ${classButton}`}
                // value={i18n.language.toUpperCase()}
                value={selectedLanguage.languageCode}
                // placeholder={i18n.language}
                classNameParent={classNameParent}
                selectedLanguage={selectedLanguage}
              />
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter="arh-transition arh-ease-out arh-duration-200"
              enterFrom="arh-opacity-0 arh-translate-y-1"
              enterTo="arh-opacity-100 arh-translate-y-0"
              leave="arh-transition arh-ease-in arh-duration-150"
              leaveFrom="arh-opacity-100 arh-translate-y-0"
              leaveTo="arh-opacity-0 translate-y-1"
            >
              <Popover.Panel>
                <ul className="arh-absolute arh-left-0 arh-right-12 arh-z-10 arh-mt-1 arh-flex arh-max-h-72 arh-w-full arh-list-none arh-flex-col arh-space-y-1 arh-overflow-y-auto arh-rounded-lg arh-bg-white arh-p-2 arh-shadow-xs">
                  {Languages.map((option, index) => (
                    <li
                      key={option._id}
                      className="arh-mx-0 arh-my-[0.375rem] arh-flex arh-h-[2.5rem] arh-cursor-pointer arh-items-center arh-space-x-[10px] arh-bg-white  mobile-768:arh-space-x-0 mobile-768:arh-ml-[10px] hover:arh-bg-greyColor"
                      onClick={() => {
                        onChangeLanguage(option);
                        close();
                      }}
                    >
                      <img
                        src={option.iconeCode || defaultUserImage}
                        className="arh-object-cover"
                        alt="..."
                      />
                      <span className="arh-flex-1 arh-font-poppins arh-text-xs arh-font-medium arh-text-black arh-text-center mobile-768:arh-hidden">
                        {option.languageCode}
                      </span>
                    </li>
                  ))}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

export default React.memo(LanguageNav);
