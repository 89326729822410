import BaseService from "@services/base.service";
import http from "../../http-common"

import {
  checkEnterpriseId,
  customizeError,
  getResponseData
} from "@helpers/_functions";

class ProjectService extends BaseService {
  constructor() {
    super("projects");
  }
  getByEmployer(employerId, params = {}){
    return http
      .get(`${this.path}/filterByEmployer/${employerId}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
  filterByEntreprise(enterpriseId, params = {}){
    enterpriseId = checkEnterpriseId(enterpriseId);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    }
    return http
      .get(`${this.path}/filterByEnterprise/${enterpriseId}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
  getProjectToExport(enterpriseId, params = {}) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    }
    return http 
      .get(`${this.path}/to-export/${enterpriseId}`, { params })
      .then((response) => getResponseData(response));
  }
}

export const Project = new ProjectService();