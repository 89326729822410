/**********************************
 *  Some elementaries functions     *
 **********************************/
import React from "react";

import { createTheme } from "@mui/material/styles";
import { getEmplacementByEnterprise } from "@routes/gettingAllData";
import imageCompression from "browser-image-compression";
import { formatDistanceToNow } from "date-fns";
import uniqueId from "lodash/uniqueId";
import moment from "moment-timezone";
import mongoose from "mongoose";
import * as locales from "react-date-range/dist/locale";
import { toArray } from "react-emoji-render";

import BlackTooltip from "@components/tooltip/BlackTooltip";

import { DocumentStorage, EmployerEmplacement } from "@services/all.services";
import { Users } from "@services/all.services";

import {
  congeCategoriesPossibleValue,
  defaultUserImage,
  FileType,
  INFO_ACTION,
  ERROR,
  SUCCESS_ACTION,
  WARNING_ACTION,
  REGEX_NOT_NUMBER,
  REGEX_PHONE,
  REGEX_EMAIL,
  statusColors,
  REGEX_CHECK_LINKS,
  VACANCE,
  ABSENCE,
  MALADIE,
} from "./_constants";

export const timePickerTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: "#292929",
          backgroundColor: "#F9FAFC",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#fcb698",
      contrastText: "#fff",
    },
  },
});

export const customizeError = (err) => {
  let err_message = "Erreur inconnu!";
  if (err) {
    if (err.error) {
      err = err.error;
    }
    if (err.message) {
      err_message = err.message;
    } else if (err.msg) {
      err_message = err.msg;
    } else if (typeof err.data?.error === "string") {
      err_message = err.data.error;
    }
  }
  let errorCustomized = {
    success: false,
    error: err,
    status: false,
    message: err_message,
  };
  return errorCustomized;
};

export const parseValidNumber = (_number, _fixed = 2) =>
  parseFloat(Number(_number).toFixed(_fixed));
// Get url of bucket on Wasabi
export const _s3 = (path) => {
  return process.env.REACT_APP_BUCKET_URL + path;
};

//check user id
export const checkUserId = (userId) => {
  if (!userId) {
    userId = localStorage.getItem("userId");
  }
  return userId;
};

//check enterprise id
export const checkEnterpriseId = (enterpriseId) => {
  if (!enterpriseId) {
    enterpriseId = localStorage.getItem("enterpriseId");
  }
  return enterpriseId;
};

//Check user permissions
export const checkPermission = (userPermissions, permissionLabel) => {
  let permitted = false;
  for (let i in userPermissions) {
    if (userPermissions[i]?.[0]) {
      let labelIndex = userPermissions[i][0].indexOf(permissionLabel);
      if (labelIndex >= 0) {
        permitted = true;
      }
    }
  }
  return permitted;
};

// Check user permissions tabs v0.2
export const checkPermissionTab = (permittedTabs, permissionLabel) => {
  if (!Array.isArray(permittedTabs)) {
    return false;
  }
  return Array.isArray(permissionLabel)
    ? permissionLabel.some((label) => permittedTabs.includes(label))
    : permittedTabs.includes(permissionLabel);
};

//Check api response data
export const getResponseData = (response, single = false) => {
  if (response?.status === 200) {
    if (single && response.data.data?.[0]) return response.data.data[0];
    else return response.data.data;
  } else {
    return Promise.reject(customizeError(response.data));
  }
};

// Check api response data and track error
export const getResponseQuery = (query) => {
  return query
    .then((response) => getResponseData(response))
    .catch((err) => {
      return Promise.reject(customizeError(err));
    });
};

//search employers by key name
export const getEmployersSearchResult = (allEmployers, searchKey) => {
  let result = [];
  if (allEmployers?.[0]?.Employer?.personnalInformation?.[0] && searchKey) {
    for (const employer of allEmployers) {
      let employerName = getFullNameByEmployer(employer.Employer);
      let keyIndex = employerName
        .toLowerCase()
        .indexOf(searchKey.toLowerCase());
      if (keyIndex >= 0) {
        result.push(employer);
      }
    }
  } else {
    result = allEmployers ? allEmployers : [];
  }
  return result;
};

//get fullname of employer in employer dropdowns
export const getEmployerFullName = (employer) =>
  (employer?.firstName ? employer.firstName + " " : "") +
  (employer?.name ? employer.name : "");

export const getEmplacementName = (emplacement) => {
  let name = "";
  if (emplacement?.name) {
    name += emplacement.name;
  }
  if (emplacement?.name) {
    name += (name ? " " : "") + emplacement.name;
  }
  return name;
};

//get fullname of employer from Employer personal info
export const getFullNameByEmployer = (Employer) =>
  (Employer?.legalFirstName ? Employer.legalFirstName + " " : "") +
  (Employer?.legalName ? Employer.legalName : "");

//get dates and hours in the header of  popup for creating conge
export const getDatesAndHoursHeading = (startDate, endDate) => {
  let datesHeader = "--";
  let hoursHeader = "--";
  if (
    startDate &&
    endDate &&
    moment(startDate).isValid() &&
    moment(endDate).isValid()
  ) {
    startDate = moment(startDate);
    endDate = moment(endDate);
    if (startDate.format("DD/MM/YYYY") === endDate.format("DD/MM/YYYY")) {
      datesHeader = startDate.format("DD MMMM YYYY");
    } else {
      let startDateFormat = "DD MMMM YYYY";
      let endDateFormat = "DD MMMM YYYY";
      if (startDate.format("MM/YYYY") === endDate.format("MM/YYYY")) {
        startDateFormat = "DD";
        endDateFormat = "DD MMMM YYYY";
      } else if (startDate.format("YYYY") === endDate.format("YYYY")) {
        startDateFormat = "DD MMMM";
        endDateFormat = "DD MMMM YYYY";
      }
      datesHeader =
        startDate.format(startDateFormat) + "-" + endDate.format(endDateFormat);
    }
    hoursHeader = startDate.format("HH:mm") + "-" + endDate.format("HH:mm");
  } else if (startDate && moment(startDate).isValid()) {
    startDate = moment(startDate);
    datesHeader = startDate.format("DD MMMM YYYY").toUpperCase();
    hoursHeader = startDate.format("HH:mm");
  } else if (endDate && moment(endDate).isValid()) {
    endDate = moment(endDate);
    datesHeader = endDate.format("DD MMMM YYYY").toUpperCase();
    hoursHeader = endDate.format("HH:mm");
  }
  return { dates: datesHeader, hours: hoursHeader };
};

//get the employer conge remaining day by the value of category
export const getEmployerRemainingDayByCategory = (employer, category) => {
  let availableDay = 0;
  if (employer?.holidayRemaining && category) {
    let holidayRemaining = employer?.holidayRemaining;
    switch (congeCategoriesPossibleValue.indexOf(category?.value)) {
      case 0: //category === "Absence"
        availableDay = checkRemaingDay(holidayRemaining.hoursMiss);
        break;
      case 1: //category === "Vacances"
        availableDay = holidayRemaining.hoursIllimited
          ? Infinity
          : checkRemaingDay(holidayRemaining.hoursHoliday);
        break;
      case 2: //category === "Maladie"
        availableDay = checkRemaingDay(holidayRemaining.hoursSick);
        break;
      default:
        availableDay = 0;
    }
  }
  return availableDay;
};
const checkRemaingDay = (remainingDay) => {
  return parseFloat(remainingDay) ? remainingDay : 0;
};
export const getUserPhoto = (userPhoto) => {
  let photo = "";
  if (userPhoto?.profileImg) {
    photo = userPhoto.profileImg;
  } else if (userPhoto?.[0]?.profileImg) {
    photo = userPhoto[0].profileImg;
  }
  return getUrlPhoto(photo);
};
export const getUrlPhoto = (databaseUrl) => {
  return databaseUrl || defaultUserImage;
};
export const parseNumberToInt = (value) => {
  const parsedValue = parseInt(value);
  return isNaN(parsedValue) ? "" : Math.abs(parsedValue);
};
export const parseNumberToFloat = (value) => {
  if (value && value.indexOf(".") === value.length - 1) {
    return value;
  } else {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? "" : Math.abs(parsedValue);
  }
};
export const formatNumberWithDecimals = (value, decimalPlaces) => {
  const regex = new RegExp(`[^\\d\\.${decimalPlaces > 0 ? "" : "\\-"}]`, "g");
  const parsedValue = value.replace(regex, "");

  if (
    parsedValue.includes(".") &&
    parsedValue.indexOf(".") !== parsedValue.lastIndexOf(".")
  ) {
    const parts = parsedValue.split(".");
    const cleanedValue = `${parts[0]}.${parts.slice(1).join("")}`;
    return cleanedValue;
  } else {
    return parsedValue;
  }
};

export const getFileTitle = (key) => {
  if (key?.length) {
    return key.substring(key.lastIndexOf("/") + 1);
  }
  return key;
};
export const getExtensionFile = (key) => {
  const fileTitle = getFileTitle(key);
  let fileExtension = "";
  if (fileTitle) {
    fileExtension = fileTitle.substring(fileTitle.lastIndexOf(".") + 1);
  }
  return fileExtension;
};
export const debounce = (callback, delay = 1000) => {
  var time;
  return (...args) => {
    clearTimeout(time);
    time = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};
export const getFileName = (key) => {
  const fileTitle = getFileTitle(key);
  let fileName = "";
  if (fileTitle) {
    fileName = fileTitle.substring(0, fileTitle.lastIndexOf("."));
  }
  return fileName;
};
export const twoDigit = (value) => {
  const parsedValue = parseInt(value);
  return parsedValue < 10 ? "0" + parsedValue : value;
};
// export const getParentFolderByKeyAndEmployer = (parentKey, employerId) => new Promise ((resolve, reject) => {
export const getParentFolderByKeyAndEmployer = async (
  parentKey,
  employerId,
) => {
  var dataParentFolder;
  if (parentKey !== "") {
    await DocumentStorage.getParentByKey({
      key: btoa(parentKey),
      // key: parentKey,
      employerId,
    })
      .then((parentFolderResponse) => {
        if (parentFolderResponse.data?.data) {
          const parentFolder = parentFolderResponse.data.data;
          const subDataParentFolder = {
            parent: parentFolder._id,
            visibility: parentFolder.isVisible,
          };
          // resolve(subDataParentFolder);
          dataParentFolder = subDataParentFolder;
        }
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
    return dataParentFolder;
  } else {
    // resolve({});
    return null;
  }
};
// });
export const removeDuplicatesItem = (array) => {
  const result = [];
  for (let i = 0; i < array.length; i++) {
    let exists = false;
    for (let j = 0; j < result.length; j++) {
      if (array[i] === result[j]) {
        exists = true;
        break;
      }
    }
    if (!exists) {
      result.push(array[i]);
    }
  }
  return result;
};

// return format UTC+ time zone
export const getUtcTimeZone = (timeZone) => {
  if (timeZone) {
    if (timeZone.indexOf("UTC") < 0) {
      let hourTimestamps = moment.tz(timeZone).format("Z");
      hourTimestamps = "UTC" + hourTimestamps + " " + timeZone;
      return hourTimestamps;
    } else {
      return timeZone;
    }
  } else {
    return "";
  }
};

//converting data object filemanager
export const dataConvertExternalLink = (data, sourceKey, isOnMyFile) => {
  data.sort(function compare(a, b) {
    if (a.key < b.key) return -1;
    if (a.key > b.key) return 1;
    return 0;
  });
  let array = [];
  for (const element of data) {
    if (
      element.key.indexOf(sourceKey) === -1 &&
      element.type === FileType["folder"] &&
      !element.isALink
    )
      array.push(element.key.split("/"));
  }
  for (let element of array) {
    if (element[element.length - 1] === "") {
      element.splice(-1, 1);
    }
    element.unshift("Documents");
  }
  let tree = arrangeIntoTreeExternalLink(array, isOnMyFile);
  tree = tree.length ? tree : [{ label: "Documents", value: "" }];
  return tree;
};
const arrangeIntoTreeExternalLink = (paths, isOnMyFile) => {
  let tree = [];
  for (const path of paths) {
    let currentLevel = tree;
    let value = "";
    for (let j = 0; j < path.length; j++) {
      let part = path[j];
      if (j > 0) value += path[j] + "/";
      let existingPath = findWhere(currentLevel, "label", part);
      if (existingPath) {
        if (!existingPath.children) {
          existingPath.children = [];
        }
        if (!isOnMyFile) currentLevel = existingPath.children;
      } else {
        let newPart = {
          label: part,
          value: value,
        };
        currentLevel.push(newPart);
        if (j < path.length - 1) {
          newPart.children = [];
          currentLevel = newPart.children;
        }
      }
    }
  }
  tree = filterTreeChildren(tree);
  return tree;
};
export const dataConvert = (data, sourceKey, isOnMyFile) => {
  data.sort(function compare(a, b) {
    if (a.key < b.key) return -1;
    if (a.key > b.key) return 1;
    return 0;
  });
  let array = [];
  for (const element of data) {
    if (
      element.key.indexOf(sourceKey) === -1 &&
      element.type === FileType["folder"] &&
      !element.isALink
    )
      array.push(element.key.split("/"));
  }
  for (let element of array) {
    if (element[element.length - 1] === "") {
      element.splice(-1, 1);
    }
    element.unshift("Documents");
  }
  let tree = arrangeIntoTree(array, isOnMyFile);
  tree = tree.length ? tree : [{ label: "Documents", value: "" }];
  return tree;
};

const arrangeIntoTree = (paths, isOnMyFile) => {
  let tree = [];
  for (const path of paths) {
    let currentLevel = tree;
    let value = "";
    for (let j = 0; j < path.length; j++) {
      let part = path[j];
      if (j > 0) value += path[j] + "/";
      let existingPath = findWhere(currentLevel, "label", part);
      if (existingPath) {
        if (!existingPath.children) {
          existingPath.children = [];
        }
        if (!isOnMyFile) currentLevel = existingPath.children;
      } else {
        let newPart = {
          label: part,
          value: value,
        };
        currentLevel.push(newPart);
        if (j < path.length - 1) {
          newPart.children = [];
          currentLevel = newPart.children;
        }
      }
    }
  }
  tree = filterTreeChildren(tree);
  return tree;
};
const findWhere = (array, key, value) => {
  let t = 0;
  while (t < array.length && array[t][key] !== value) {
    t++;
  }
  if (t < array.length) {
    return array[t];
  } else {
    return false;
  }
};

const filterTreeChildren = (tree) => {
  return tree?.map((node) => {
    if (node?.children?.length) {
      node.children = node?.children.filter((child) => child.value !== "/");
    }
    return node;
  });
};

export const formatDate = (date) => {
  let dateFormatted = "";
  if (date && moment(date).isValid()) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    dateFormatted = [year, month, day].join("-");
  }
  return dateFormatted;
};
export const getCommentsLength = (NewsComment, language) => {
  let Comments = language === "en" ? "Comment" : "Commentaire";
  let comment = "0 " + Comments;
  if (NewsComment?.length) {
    if (NewsComment?.[0]?.name) {
      const newsCommentsLength = NewsComment.length;
      if (newsCommentsLength === 1) {
        comment = "1 " + Comments;
        return comment;
      } else {
        comment = newsCommentsLength + " " + Comments + "s";
        return comment;
      }
    }
  }
  return comment;
};
export const getLikesLength = (likeState, language) => {
  let like = language === "en" ? "Like" : "J'aime";
  let likes = language === "en" ? "Likes" : "J'aimes";
  let likeReturned = "0 " + like;
  if (likeState === 1) {
    likeReturned = "1 " + like;
  } else if (likeState === 0) {
    likeReturned = "0 " + like;
  } else {
    likeReturned = likeState + " " + likes;
  }
  return likeReturned;
};
export const likeCount = (newsLikes) => {
  let likeState = 0;
  if (newsLikes?.length) {
    // const newsLikeLength = NewsLike.length
    for (let newsLike of newsLikes) {
      if (newsLike.statusLike) {
        likeState++;
      }
    }
  }
  return likeState;
};

export const compareEmployersFullName = (employerA, employerB) => {
  const AFullName = getFullNameByEmployer(employerA.personnalInformation?.[0]);
  const BFullName = getFullNameByEmployer(employerB.personnalInformation?.[0]);
  if (AFullName < BFullName) {
    return -1;
  }
  if (AFullName > BFullName) {
    return 1;
  }
  return 0;
};

export const getFilterTabs = (params) => {
  const Tabs = [];

  for (let i = 0; i < params.length; i++) {
    let exists = false;
    for (let j = 0; j < Tabs.length; j++) {
      if (params[i]._id === Tabs[j]._id) {
        exists = true;
        break;
      }
    }
    if (!exists) {
      Tabs.push(params[i]);
    }
  }

  return Tabs;
};
export const getFilterTabsForGroup = (params) => {
  const Tabs = [];

  for (let i = 0; i < params.length; i++) {
    let exists = false;
    for (let j = 0; j < Tabs.length; j++) {
      if (params[i] === Tabs[j]) {
        exists = true;
        break;
      }
    }
    if (!exists) {
      Tabs.push(params[i]);
    }
  }

  return Tabs;
};

export const compareFilesAndFolder = (dataFiles) => {
  dataFiles.sort(function compare(a, b) {
    let keyA = a.key.split("/");
    let keyB = b.key.split("/");
    if (keyA[keyA.length - 1] !== "") return -1;
    else if (keyB[keyB.length - 1] !== "") return 1;
    else return 0;
  });
};

export const compareByName = (name1, name2) => {
  if (
    (
      name1?.Employer?.personnalInformation?.[0]?.legalFirstName +
      " " +
      name1?.Employer?.personnalInformation?.[0]?.legalName
    ).toLowerCase() <
    (
      name2?.Employer?.personnalInformation?.[0]?.legalFirstName +
      " " +
      name2?.Employer?.personnalInformation?.[0]?.legalName
    ).toLowerCase()
  ) {
    return -1;
  } else {
    return 1;
  }
};

export const getIdForSplitURL = (params) => {
  var url = window.location.href;
  let urlSplit = url.split("/");
  let id = urlSplit[params];
  return id;
};

export const getHourTimezone = (hour, timezone) => {
  let dateTimezone = null;
  if (hour?.date && moment(hour?.date).isValid()) {
    if (!timezone || !hour?.timezone) {
      dateTimezone = new Date(hour.date);
    } else {
      let dateWithTimezoneEmploye = moment
        .tz(hour.date, hour.timezone)
        .format();
      dateTimezone = new Date(
        moment(dateWithTimezoneEmploye).tz(timezone).format("YYYY-MM-DD HH:mm"),
      ); // date après conversion
    }
  }
  return dateTimezone;
};

export const getTimezoneName = (timezone) => {
  if (timezone && timezone.split(" ")?.length > 0) {
    let timeZone = timezone.split(" ");
    if (timeZone?.[0]) {
      if (timeZone?.[1]) {
        return timeZone[1];
      } else {
        return timeZone[0];
      }
    }
  } else {
    return moment.tz.guess();
  }
};

export const getDateWithoutTimezome = (date) => {
  if (moment(date).isValid()) {
    return moment(date)
      .utcOffset(moment().utcOffset())
      .tz("UTC", true)
      .set({ s: 0, ms: 0 });
  }
  return date;
};
export const validateDateIntoAPeriod = (date, start, end) => {
  if (
    moment(date).isValid() &&
    moment(start).isValid() &&
    moment(end).isValid()
  ) {
    return getDateWithoutTimezome(date).isBetween(
      moment(start),
      moment(end),
      null,
      "[]",
    );
  }
  return date;
};

export const getTimezoneByEmployeId = async (employeId) => {
  const valueEmplacement = await EmployerEmplacement.getByEmployer(employeId);
  let listEmplacements = valueEmplacement?.data?.data?.[0]?.Emplacement;
  if (listEmplacements) {
    listEmplacements.sort((emplacement1, emplacement2) => {
      if (emplacement1._id < emplacement2._id) return -1;
      if (emplacement1._id > emplacement2._id) return 1;
      return 0;
    });
  }
  if (listEmplacements?.[0]?.timeZone) {
    return getTimezoneName(listEmplacements?.[0]?.timeZone);
  } else {
    return moment.tz.guess();
  }
};

export const getAllTimezoneByEmployeId = async (employeId) => {
  const valueEmplacement = await EmployerEmplacement.getByEmployer(employeId);
  let listEmplacements = valueEmplacement?.data?.data?.[0]?.Emplacement;
  if (listEmplacements) {
    listEmplacements.sort((emplacement1, emplacement2) => {
      if (emplacement1._id < emplacement2._id) return -1;
      if (emplacement1._id > emplacement2._id) return 1;
      return 0;
    });
  }
  let timezones = [];
  for (let emplacement of listEmplacements) {
    timezones.push(getTimezoneName(emplacement.timeZone));
  }
  return timezones;
};

export const getMomentHourTimezone = (hour, timezone) => {
  const START = moment(hour).format("YYYY-MM-DD HH:mm");
  return moment.tz(START, timezone).format();
};

export const getEmployerFullLegalName = (employer) =>
  employer?.personnalInformation?.[0]?.legalFirstName +
  " " +
  employer?.personnalInformation?.[0]?.legalName;

export const formatDateToMoment = (date) => {
  return date && moment(date).isValid()
    ? moment.tz(date, "UTC").format("DD/MM/YYYY")
    : null;
};

export const putNoteToANewLine = (note) => {
  if (note?.length && note?.[0]) {
    return note?.split("\n")?.map((data, key) => {
      return <div key={uniqueId()}>{data}</div>;
    });
  }
};

export const removeANewLine = (note) => {
  if (note) return note?.replace(/\n/g, "");
};

export const scrollIntoView = (el) => {
  if (typeof el?.scrollIntoView === "function") {
    el.scrollIntoView();
    el.classList.add("colorFlash");
  }
};

export const isValidFile = (location, regexp = false) => {
  if (regexp) return location.match(regexp);
  else return true;
};

export const getFolderToExpend = (allNodes) => {
  let expanded = [];
  const setExpanded = (nodes) => {
    if (Array.isArray(nodes)) {
      for (const node of nodes) {
        if (node.children) {
          expanded.push(node.value);
          setExpanded(node.children);
        }
      }
    }
  };
  setExpanded(allNodes);
  return expanded;
};

export const dayHourToHour = (day, hour, hourPerDay = 24) => {
  hour = parseFloat(hour) || 0;
  day = parseFloat(day) || 0;

  day = +parseFloat(day * hourPerDay).toFixed(2);
  return day + hour;
};

// Checking if is a valid JSON string
export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseJsonObject = (obj) => {
  try {
    obj = JSON.parse(obj);
    return obj;
  } catch (e) {
    return {};
  }
};

//date picker helper
function isPreviousButton(element) {
  return (
    element?.firstElementChild &&
    element?.firstElementChild?.className?.includes("years-previous")
  );
}

function isUpCummingButton(element) {
  return (
    element?.firstElementChild &&
    element?.firstElementChild?.className?.includes("years-upcoming")
  );
}

function textContent(element) {
  return +element?.innerText?.replace("✓\n", "") || "";
}

function sortYear(a, b) {
  if (isPreviousButton(a) || isUpCummingButton(b)) {
    return -1;
  }
  if (isUpCummingButton(a) || isPreviousButton(b)) {
    return 1;
  }

  return textContent(a) - textContent(b);
}

export function reverseDropwdownYear() {
  setTimeout(() => {
    const yearDropdown = this.firstElementChild;
    if (yearDropdown?.childNodes?.length) {
      let childElements = Array.from(yearDropdown.childNodes);
      childElements.sort(sortYear);
      yearDropdown.append(...childElements);
    }

    // scroll vers l'année selectionnée
    const datePickerDropdown = document.querySelector(
      ".react-datepicker__year-dropdown",
    );
    if (datePickerDropdown) {
      const selectedYear = document.querySelector(
        ".react-datepicker__year-option--selected_year",
      );
      if (selectedYear && selectedYear.offsetTop) {
        datePickerDropdown.scroll(0, selectedYear.offsetTop - 5);
      }
    }
  }, 0); //<- permet d'attendre que le dropdown s'affiche
}

// @deprecated
export function getGroupName(data = []) {
  let result = data;
  for (let value of data) {
    let groupeName = [];
    for (let res of value?.groupe) {
      if (!groupeName.includes(res?.Groupe?.[0]?.title)) {
        groupeName.push(res?.Groupe?.[0]?.title);
      }
    }
    value.groupeName = groupeName?.length ? groupeName.join(", ") : "Aucun";
    if (value?.emplacement) {
      let emplacementName = [];
      for (let res of value?.emplacement) {
        emplacementName.push(res?.id?.name);
      }
      value.emplacementName = emplacementName?.length
        ? emplacementName.join(", ")
        : "Aucun";
    }
  }
  return result;
}

//Replace {first_name}, {last_name} and {job_title} in email content
export const replaceAllContentVars = (content, candidate) => {
  if (typeof content === "string" && candidate) {
    content = content
      .replaceAll("{applicant_firstname}", candidate.firstname)
      .replaceAll("{applicant_lastname}", candidate.name)
      .replaceAll("{job_title}", candidate.job?.title);
  }
  return content;
};

export const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

//add enterprise name before email from params
export const setParamsEmailFrom = (email) => {
  const enterpriseName = JSON.parse(
    localStorage.getItem("Enterprise"),
  )?.enterpriseName;
  if (email && enterpriseName) {
    email = enterpriseName + " <" + email + ">";
  }
  return email;
};

//get candidate full name
export const getCandidateFullName = (candidate) =>
  (candidate?.firstname ? candidate.firstname + " " : "") +
  (candidate?.name ? candidate.name : "");

//parse a string to array (customized JSON array parse)
export const forceParseToArray = (s) =>
  !s
    ? []
    : s.indexOf("[") === 0 && s.lastIndexOf("]") >= s.length - 1
      ? JSON.parse(s)
      : Array.isArray(s)
        ? s
        : [s];

//unwind an array of object by one property
// ex: arr = [ {a: 1, b:[2, 3] } ] => unwindArray(arr, "b") = [ {a: 1, b: 2 }, {a: 1, b: 3 } ]
export const unwindArray = (arr, prop) => {
  if (Array.isArray(arr) && typeof prop === "string") {
    arr = arr.reduce((acc, item) => {
      let unwindedItems = [item];
      if (Array.isArray(item[prop])) {
        unwindedItems = item[prop].map((p) => ({ ...item, [prop]: p }));
      }
      return acc.concat(unwindedItems);
    }, []);
  }
  return arr;
};

const checkCompareValue = (compare) => {
  switch (typeof compare) {
    case "undefined":
      return (val) => val === null;
    case "function":
      return compare;
    default:
      return (val) => val === compare;
  }
};

// remove all null properties in object, if the compare function is not given
export const removeNullProperties = (obj, compare) => {
  compare = checkCompareValue(compare);
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    let hasProperties = value && Object.keys(value).length > 0;
    if (compare(value)) {
      delete obj[key];
    } else if (typeof value !== "string" && hasProperties) {
      removeNullProperties(value, compare);
    }
  });
  return obj;
};

// deep clone value of one variable or constant
export const cloneValue = (val) => JSON.parse(JSON.stringify(val));

export const absoluteUrl = (url) =>
  (/^(http(s)?:\/\/){1}/.test(url) ? "" : "http://") + url;

export const getRGBA = (r, g, b, a) => {
  return "rgba(" + r + "," + g + "," + b + "," + a + ")";
};

export function setTimezoneHours(hours, timezone) {
  let newHours = [...hours];
  let timezoneValue = null;
  if (Boolean(timezone)) {
    timezoneValue = getTimezoneName(timezone);
  }
  newHours = newHours.map((hour) => {
    if (!timezone) timezoneValue = hour.Timezone || hour.timezone;
    hour.start = getDateToRender(hour.startDate, timezoneValue);
    hour.end = getDateToRender(hour.endDate, timezoneValue);
    if (moment(hour.end).format("HHmm") === "0000") {
      hour.end = moment(hour.end).add(-1, "minutes").toDate();
    }
    return hour;
  });
  return newHours;
}

//for recrutement
export const convertURLToFile = async (url, data) => {
  let blob = await fetch(url).then((r) => r.blob());
  let file = new File([blob], data.filename, { type: data.mimetype });
  return file;
};

//calculate hours remain (sick, miss, vacancy) by renewal date and hiring date
// **** used when employer set vacancies hours but not set the current hours
export const calculateHoursRemaining = (
  renewalDay,
  hiringDate,
  hoursPerYears,
) => {
  let yearsDiff = "",
    hoursRemain = "";
  renewalDay = moment(renewalDay).set("hour", 0);
  hiringDate = moment(hiringDate).set("hour", 0);
  hoursPerYears = parseFloat(hoursPerYears);
  if (renewalDay <= hiringDate || renewalDay <= moment()) {
    yearsDiff = moment(renewalDay.clone().add(1, "years")).diff(
      hiringDate,
      "years",
      true,
    );
  } else {
    yearsDiff = moment(renewalDay).diff(hiringDate, "years", true);
  }
  if (yearsDiff < 1) {
    let monthDiff = "";
    if (renewalDay <= hiringDate) {
      monthDiff = moment(renewalDay.clone().add(1, "years")).diff(
        hiringDate,
        "months",
        true,
      );
    } else {
      monthDiff = moment(renewalDay).diff(hiringDate, "months", true);
    }
    hoursRemain = isNaN(hoursPerYears)
      ? ""
      : ((hoursPerYears * monthDiff) / 12).toFixed(2);
  } else {
    hoursRemain = hoursPerYears;
  }
  return hoursRemain;
};

export const checkHoursHolidaysRemaining = (employeSalary = {}) => {
  const holiday = employeSalary.holiday;
  let holidayRemaining = employeSalary.holidayRemaining;
  const hiringDate = employeSalary.job?.hiringDate;
  if (holiday?.hoursIllimited && holidayRemaining?.hoursHoliday === "") {
    holidayRemaining.hoursIllimited = true;
  } else if (
    !holiday?.hoursIllimited &&
    !holidayRemaining?.hoursIllimited &&
    holiday?.hoursHoliday !== "" &&
    holidayRemaining?.hoursHoliday === ""
  ) {
    holidayRemaining.hoursHoliday = calculateHoursRemaining(
      holiday.renewalDay,
      hiringDate,
      holiday.hoursHoliday,
    );
  }
  if (holiday?.hoursSick !== "" && holidayRemaining?.hoursSick === "") {
    holidayRemaining.hoursSick = calculateHoursRemaining(
      holiday.renewalDay,
      hiringDate,
      holiday.hoursSick,
    );
  }
  if (holiday?.hoursMiss !== "" && holidayRemaining?.hoursMiss === "") {
    holidayRemaining.hoursMiss = calculateHoursRemaining(
      holiday.renewalDay,
      hiringDate,
      holiday.hoursMiss,
    );
  }
  return holidayRemaining;
};

export function formatHolidays(employerSalary = {}) {
  const holiday = employerSalary?.holiday;
  const holidayRemaining = employerSalary?.holidayRemaining;

  return {
    [VACANCE]: {
      /** @type {string} */
      total: holiday?.hoursHoliday || "",

      /** @type {string} */
      remaining: holidayRemaining?.hoursHoliday || "",

      /** @type {boolean} */
      hoursIllimited: holidayRemaining?.hoursIllimited || false,
    },
    [ABSENCE]: {
      /** @type {string} */
      total: holiday?.hoursMiss || "",

      /** @type {string} */
      remaining: holidayRemaining?.hoursMiss || "",
    },
    [MALADIE]: {
      /** @type {string} */
      total: holiday?.hoursSick || "",

      /** @type {string} */
      remaining: holidayRemaining?.hoursSick || "",
    },
    /** @type {string} */
    hoursPerDay: holiday?.hoursPerDay || "",
  };
}

export function getDateFormat(startDate, endDate, language) {
  let start = moment(startDate).format("dddd D MMMM YYYY");
  let end = moment(endDate).format("dddd D MMMM YYYY");
  if (start === end) {
    return start;
  } else {
    end = moment(endDate).format("D MMMM YYYY");
    if (
      moment(startDate).format("MMMM YYYY") ===
      moment(endDate).format("MMMM YYYY")
    ) {
      start = moment(startDate).format("D");
    } else if (
      moment(startDate).format("YYYY") === moment(endDate).format("YYYY")
    ) {
      start = moment(startDate).format("D MMMM");
    } else {
      start = moment(startDate).format("D MMMM YYYY");
    }

    if (language === "fr") {
      return start + " au " + end;
    } else if (language === "en") {
      return start + " to " + end;
    }
  }
}

export const setTimeZone = async (emplacements) => {
  let timezoneValue = await Users.getUserConnected(
    localStorage.getItem("userId"),
  )
    .then(async (response) => {
      const userData = response?.data?.data;
      const userTimezone = userData?.timezoneEnterprise
        ? userData?.timezoneEnterprise
        : userData?.timezone;
      const zone = userData?.zone ? userData?.zone : "";
      let timezone = "";
      if (userTimezone && userTimezone !== "") {
        // let emplacements = this.props.emplacements
        if (!emplacements) {
          let response = await getEmplacementByEnterprise();
          emplacements = response.emplacements;
        }
        timezone = emplacements?.filter(
          (emplacement) => emplacement?.timeZone === userTimezone,
        );
        timezone = timezone?.length > 0 && userTimezone;
      }
      return { timeZoneValue: timezone, zone };
    })
    .catch((error) => {
      console.log(error);
    });
  return timezoneValue;
};

export const daysAndHoursLeave = (startDate, endDate, nbHoursPerDay) => {
  let nbMinutes = moment(endDate).diff(startDate, "minutes");
  let nbHours = Math.floor(nbMinutes / 60);
  let manualDayLeave = Math.floor(nbHours / 24);
  let manualHoursLeave = nbHours % 24;
  nbMinutes = nbMinutes % 60;
  manualHoursLeave += Math.round((nbMinutes / 60) * 100) / 100;
  if (manualHoursLeave >= nbHoursPerDay) {
    manualDayLeave++;
    manualHoursLeave = 0;
  }
  if (manualDayLeave < 0) manualDayLeave = 0;
  if (manualHoursLeave < 0) manualHoursLeave = 0;
  return { manualDayLeave, manualHoursLeave };
};

export const getSelectedAssignments = (
  assignations,
  selectedAssignment,
  elements = {},
  level = 0,
  titles = [],
) => {
  if (assignations?.length) {
    const nextLevel = level + 1;
    for (const assignation of assignations) {
      const newElements = Object.assign({}, elements, {
        ["level" + level]: assignation,
      });
      const newTitles = [...titles, assignation?.title];
      if (assignation._id === selectedAssignment) {
        return {
          selectedAssignement: newElements,
          titles: newTitles?.join(" / "),
        };
      } else if (assignation.childElements?.length) {
        elements = getSelectedAssignments(
          assignation.childElements,
          selectedAssignment,
          newElements,
          nextLevel,
          newTitles,
        );
      }
    }
  }
  return elements;
};

export function diacriticSensitiveRegex(string = "") {
  return string
    .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    .replaceAll(/ae/g, "[ae,æ]")
    .replaceAll(/oe|œ/g, "[oe,œ]")
    .replaceAll(/AE|Æ/g, "[AE,Æ]")
    .replaceAll(/OE|Œ/g, "[OE,Œ]")
    .replaceAll(/a|á|à|â|ä|å|ã/g, "[a,á,à,â,ä,å,ã]")
    .replaceAll(/c|ç/g, "[c,ç]")
    .replaceAll(/e|é|è|ê|ë/g, "[e,é,è,ê,ë]")
    .replaceAll(/i|í|ì|î|ï/g, "[i,í,ì,î,ï]")
    .replaceAll(/n|ñ/g, "[n,ñ]")
    .replaceAll(/o|ó|ò|ô|ö|õ|ø/g, "[o,ó,ò,ô,ö,õ,ø]")
    .replaceAll(/s|š/g, "[s,š]")
    .replaceAll(/u|ú|ù|û|ü/g, "[u,ú,ù,û,ü]")
    .replaceAll(/y|ý|ÿ/g, "[y,ý,ÿ]")
    .replaceAll(/z|ž/g, "[z,ž]")
    .replaceAll(/A|Á|À|Â|Ä|Å|Ã/g, "[A,Á,À,Â,Ä,Å,Ã]")
    .replaceAll(/C|Ç/g, "[C,Ç]")
    .replaceAll(/E|É|È|Ê|Ë/g, "[E,É,È,Ê,Ë]")
    .replaceAll(/I|Í|Ì|Î|Ï/g, "[I,Í,Ì,Î,Ï]")
    .replaceAll(/N|Ñ/g, "[N,Ñ]")
    .replaceAll(/O|Ó|Ò|Ô|Ö|Õ|Ø/g, "[O,Ó,Ò,Ô,Ö,Õ,Ø]")
    .replaceAll(/S|Š/g, "[S,Š]")
    .replaceAll(/U|Ú|Ù|Û|Ü/g, "[U,Ú,Ù,Û,Ü]")
    .replaceAll(/Y|Ý|Ÿ/g, "[Y,Ý,Ÿ]")
    .replaceAll(/Z|Ž/g, "[Z,Ž]")
    .replaceAll(/'|`/g, "[',`]")
    .replaceAll(")", "[)]")
    .replaceAll("(", "[(]")
    .replaceAll("+", "[+]")
    .replaceAll("*", "[*]");
}

export const getRegSearchValue = (name) => {
  let nameReg = null;
  if (name) {
    // Extraction des propriétés des filtres
    const normalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const nameParts = normalized.split(/\s+/);

    // Construction de l'expression régulière pour chaque partie du nom
    const nameRegexParts = nameParts.map((part) => `(?=.*${part})`);
    nameReg = new RegExp(nameRegexParts.join(""), "i");
  }
  return nameReg;
};

export const searchValue = (nameReg, source) => {
  return nameReg.test(source.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
};

export const arraySearch = (value, array, attribut) => {
  if (!value) return array;
  const regSearchValue = getRegSearchValue(value);
  const newArray = array.filter((item) =>
    searchValue(regSearchValue, item[attribut]),
  );
  return newArray;
};

export const taskIndentationStyle = (indentation = 2) => {
  return { marginLeft: "" + indentation + "rem" };
};

export const showTaskHours = (hours) => {
  let h = 0,
    m = 0;
  if (hours) {
    h = parseInt(hours);
    m = parseInt((hours % 1) * 60);
  }
  return twoDigit(h) + ":" + twoDigit(m);
};

export const convertTaskHours = (hoursOrMinutes, minVersion = false) => {
  let minutes = isNaN(hoursOrMinutes) ? 0 : Math.round(hoursOrMinutes);
  minutes = minVersion ? minutes : (minutes * 60);
  const m = minutes % 60;
  return Math.floor(minutes / 60) + "h" + (m === 0 ? "" : m);
};

export const convertHourDuration = (hours) => {
  let h = 0,
    m = 0;
  if (hours) {
    h = parseInt(hours);
    if (Number.isNaN(h)) {
      h = 0;
    } else {
      m = (parseFloat(hours) * 60) % 60;
    }
  }
  return (
    "" + (h > 0 ? h + "h" : "") + (m > 0 ? twoDigit(m.toFixed(0)) + "mn" : "")
  );
};

export const convertStringToHours = (str) => {
  let time = str.split("h");
  return +time[0] + +time[1] / 60;
};

/**
 * @param { Number } hourDuration
 * @param { String | null } separatedChar
 * @returns { String }
 */
export const convertHourDurationToHourAndMinute = (
  hourDuration,
  separatedChar = ":",
) => {
  if (!hourDuration) return "";
  const hours = Math.floor(hourDuration),
    minutes = (hourDuration * 60) % 60;
  return `${twoDigit(hours)}${separatedChar}${twoDigit(minutes?.toFixed(0))}`;
};

/**
 * @returns {Date | null}
 */
export const getDateToRender = (date, timezone = "UTC") => {
  let dateToRender = null;
  if (date && moment(date).isValid())
    dateToRender = moment(
      moment.tz(date, timezone).format("YYYY-MM-DD HH:mm:ss"),
    ).toDate();
  return dateToRender;
};

export const getDateToSave = (date, timezone = "UTC") => {
  let dateToSave = null;
  if (date && moment(date).isValid())
    dateToSave = moment
      .tz(moment(date).format("YYYY-MM-DD HH:mm:ss"), timezone)
      .toDate();
  return dateToSave;
};

export const calculateTimes = (startTimes, endTimes) => {
  if (!startTimes || !endTimes)
    return {
      hours: 0,
      minutes: 0,
    };
  let hourDuration = moment(endTimes).diff(startTimes, "hours", true);
  return {
    hours: Math.floor(hourDuration),
    minutes: parseInt(((hourDuration * 60) % 60).toFixed(0)),
  };
};

export const flatEmployersTree = (employers) =>
  employers.reduce((acc, employer) => {
    let childs = [];
    if (employer.childElements.length) {
      childs = flatEmployersTree(employer.childElements);
    }
    return [...acc, employer._id, ...childs];
  }, []);

export const filterEmployeeDropDownByPermission = (
  employers,
  permissions = [],
) => {
  if (!Array.isArray(employers)) return [];
  return employers.filter(
    (e) =>
      Array.isArray(e?.permissions) &&
      e.permissions.some((p) => permissions.includes(p)),
  );
};

export const hasReachedBottom = (viewElement, toleranceMargin = 0) => {
  if (typeof viewElement === "object" && viewElement instanceof HTMLElement) {
    // version amélioré de:
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
    if (
      viewElement.scrollHeight - Math.abs(viewElement.scrollTop) <=
      viewElement.clientHeight + toleranceMargin
    ) {
      return true;
    }
  }

  return false;
};
export const setNotificationSuccess = (dispatch, message, delay = 0) => {
  setTimeout(() => {
    dispatch({
      type: "notification/STANDARD_NOTIFICATION",
      payload: {
        type: SUCCESS_ACTION,
        message,
      },
    });
  }, delay);
};
export const setNotificationWarning = (dispatch, message, delay = 0) => {
  setTimeout(() => {
    dispatch({
      type: "notification/STANDARD_NOTIFICATION",
      payload: {
        type: WARNING_ACTION,
        message,
      },
    });
  }, delay);
};

export const setNotificationError = (dispatch, message, delay = 0) => {
  setTimeout(() => {
    dispatch({
      type: "notification/STANDARD_NOTIFICATION",
      payload: {
        type: ERROR,
        message,
      },
    });
  }, delay);
};

export const hexToRgb = (hex) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ]
    : null;
};
const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

export const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};
export const setNotificationInfo = (dispatch, message, delay = 0) => {
  setTimeout(() => {
    dispatch({
      type: "notification/STANDARD_NOTIFICATION",
      payload: {
        type: INFO_ACTION,
        message,
      },
    });
  }, delay);
};

export const removeOptionalParams = (path) => path?.replace(/\/\:.+\?/g, "");

export const removeAllPathParams = (path) => path?.replace(/\/\:.+\??/g, "");

/* Traduction des parties de variables des notifications*/
const translateOptions = (options, translator) => {
  if (options) {
    for (const key in options) {
      options[key] = translator(`message:${options[key]}`);
    }
  }
  return options;
};
/*Function reduite pour la traduction du message et les variables des notifications */
export const translateNotification = (translator, message, option) => {
  return translator(`message:${message}`, translateOptions(option, translator));
};
export const translatePipelineColumnsTitle = (translator, columns) => {
  if (columns?.lanes?.[0] && typeof translator === "function") {
    columns.lanes = columns.lanes.map(({ title, ...column }) => ({
      title: translator(title),
      ...column,
    }));
  }
  return columns;
};

export const getFormat = (hourValue) => {
  if (hourValue === "0") return true;
  const formats = [
    "h",
    "hA",
    "hm",
    "hmA",
    "hh",
    "hhA",
    "hhm",
    "hhmA",
    "hmm",
    "hmmA",
    "hhm",
    "hhmA",
    "hhmm",
    "hhmmA",
    "h:",
    "h:A",
    "h:m",
    "h:mA",
    "hh:",
    "hh:A",
    "hh:m",
    "hh:mA",
    "h:mm",
    "h:mmA",
    "hh:m",
    "hh:mA",
    "hh:mm",
    "hh:mmA",
  ];
  for (let format of formats) {
    if (moment(hourValue, format, true).isValid()) return format;
  }
  return false;
};

export const validateInputHourFormat = (hourValue) => {
  if (!hourValue) return true;
  hourValue = hourValue.toUpperCase();
  hourValue = hourValue.replaceAll(" ", "");
  if (!hourValue.includes("M")) {
    hourValue = hourValue.replaceAll("A", "AM").replaceAll("P", "PM");
  }
  let format = getFormat(hourValue);
  return format;
};

export function formatToNumber(eventValue) {
  return eventValue.toString().replace(REGEX_NOT_NUMBER, "");
}

export const setStateColorRGBA = (colorPicked, tempSettings) => {
  tempSettings["rgba"] = getRGBA(
    colorPicked.r,
    colorPicked.g,
    colorPicked.b,
    0.2,
  );
  return tempSettings;
};

export const combineTwoArrays = (array1, array2) => {
  return array1.concat(array2.filter((item) => array1.indexOf(item) < 0));
};

export const getPostalCode = (place) => {
  const { long_name: postalCode = "" } =
    place?.address_components?.find((c) => c.types.includes("postal_code")) ||
    {};
  return postalCode;
};

export const getCountry = (place) => {
  const { long_name: country = "", short_name: countryCode = "" } =
    place?.address_components?.find((c) => c.types.includes("country")) || {};
  return { country, countryCode };
};

export const getProvince = (place) => {
  const { long_name: province = "" } =
    place?.address_components?.find((c) =>
      c.types.includes("administrative_area_level_1"),
    ) || {};
  return province;
};

export const getLocality = (place) => {
  const { long_name: city = "" } =
    place?.address_components?.find((c) => c.types.includes("locality")) || {};
  return city;
};

export const getCurrencyByDevise = (devise) =>
  devise ? `${devise.pays}, ${devise.monnaie} (${devise.devise})` : "";
export const toString = (mix) => (mix ? mix.toString() : "");

export const detecterDevise = (chaine) => {
  const match = chaine.match(/\(([^)]+)\)/);
  return match ? match[1] : null;
};

export const isOptionalPhoneValid = (phonenumber) =>
  !phonenumber || REGEX_PHONE.test(phonenumber);

export const isOptionalEmailValid = (email) =>
  !email || REGEX_EMAIL.test(email);

export const getDateUtcZero = (date) => {
  if (date && moment(date).isValid()) {
    date = moment(moment(date).format("DD/MM/YYYY"), "DD/MM/YYYY")
      .utcOffset(0)
      .toDate();
  }
  return date;
};

export const convertBytesAndShow = (bytes, decimals = 2) => {
  if (bytes <= 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const showDeviseInEn = (number) => {
  if (!number || isNaN(parseFloat(number))) {
    number = 0;
  }
  return parseFloat(parseFloat(number).toFixed(0)).toLocaleString("en");
};

export function removeA(arr) {
  var what,
    a = arguments,
    L = a.length,
    ax;
  while (L > 1 && arr.length) {
    what = a[--L];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}

export const upperCaseFirstLetter = (a) =>
  (a + "").charAt(0).toUpperCase() + a.substr(1);

export const formatDurationToNow = (date, locale) => {
  let lastModified = "";
  if (moment(date).isValid()) {
    lastModified = formatDistanceToNow(moment(date).toDate(), {
      addSuffix: true,
      locale: locales[locale],
    });
    lastModified = upperCaseFirstLetter(lastModified);
  }
  return lastModified;
};

export const parseEmojis = (value) => {
  if (value) {
    const emojisArray = toArray(value);
    // toArray outputs React elements for emojis and strings for other
    const newValue = emojisArray.reduce((previous, current) => {
      if (typeof current === "string") {
        return previous + current;
      }
      return previous + current.props.children;
    }, "");
    return newValue;
  }
};

export function formatDateToDateString(date) {
  if (!date || !moment(date).isValid()) {
    return "";
  }

  return moment(date).format("YYYY-MM-DD");
}

export function formatDateStringToDate(date) {
  const dateMoment = moment(date, "YYYY-MM-DD");
  if (!dateMoment.isValid()) {
    return null;
  }

  return dateMoment.toDate();
}

export function convertToFormData(args) {
  const formData = new FormData();

  // in multipart/form-data, "files" attribut should be at the end or object
  let { files = [], ...formValue } = args;
  formValue.files = files;

  for (let key in formValue) {
    if (Array.isArray(formValue[key])) {
      for (let subElement of formValue[key]) {
        formData.append(key, subElement);
      }
      continue;
    }

    formData.append(key, formValue[key]);
  }

  return formData;
}

export function formatageDate(str) {
  return moment(str).isValid() ? moment(str).format("DD/MM/YYYY") : "-";
}

export const compressedImage = async (imageFile, customOptions = {}) => {
  const options = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 500,
    useWebWorker: true,
    initialQuality: 1,
    fileType: imageFile.type,
    ...customOptions,
  };
  try {
    const Blob = await imageCompression(imageFile, options);
    const compressedFile = new File([Blob], imageFile.name, {
      type: imageFile.type,
    });
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};

export const getFileType = (data) => {
  let file = "";
  if (data?.type) {
    file = data.type.split("/");
  } else if (typeof data === "string") {
    file = data.split("/");
  }
  return file[0];
};

export const getMimeType = (data) => {
  let mime = "";
  if (data?.type) {
    mime = data.type.split("/");
  } else if (typeof data === "string") {
    mime = data.split("/");
  }
  return mime[1];
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl?.split(",");
  if (arr?.[0]) {
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
};

export const getPermissionValue = (permissionLists, permissionValues = {}) => {
  for (const [key, data] of Object.entries(permissionLists)) {
    permissionValues[key] = data.children?.length
      ? data.children.map(({ value, childs }) => {
        if (childs) {
          permissionValues = getPermissionValue(childs, permissionValues);
        }
        return value;
      })
      : [data.value];
    if (data.childs) {
      permissionValues = getPermissionValue(data.childs, permissionValues);
    }
  }
  return permissionValues;
};

export const getAllPermissionValue = (permissionLists) =>
  Object.values(getPermissionValue(permissionLists)).flat();

export const getTabValue = (permissionLists, permissionValues = {}) => {
  for (const [key, data] of Object.entries(permissionLists)) {
    permissionValues[key] = data.children?.length
      ? data.children.map(({ value, childs, defaultActive, label }) => {
        if (childs) {
          permissionValues = getTabValue(childs, permissionValues);
        }
        return {
          Tabs: value,
          defaultActive,
          label,
        };
      })
      : [
        {
          Tabs: data.value,
          defaultActive: data.defaultActive,
          label: data.label,
        },
      ];
    if (data.childs) {
      permissionValues = getTabValue(data.childs, permissionValues);
    }
  }
  return permissionValues;
};

export const getPermissionTree = (permissionLists, menusPermissions = {}) => {
  let permissionTree = Object.entries(permissionLists).map(([key, data]) => {
    let value =
      data.children?.length === 1
        ? [data.value, ...menusPermissions[key]]
        : menusPermissions[key] || data.value;
    if (data.children?.some((childItem) => childItem.childs)) {
      data.children = data.children.map((childItem) => {
        let childValue = childItem.value;
        if (childItem?.childs) {
          if (!childItem.children) childItem.children = [];
          childItem.children = childItem.children.concat(
            ...Object.values(childItem.childs).map((subChild) =>
              subChild.children.map((subChildItem) => ({
                ...subChildItem,
                parentValues: [childItem.value, subChildItem.value],
              })),
            ),
          );
          const subChildsValues = childItem.children.map(
            (subChild) => subChild.value,
          );
          value = value.concat(subChildsValues);
          childValue = [childValue, ...subChildsValues];
          value.push(childValue.join(","));
        }
        return {
          ...childItem,
          value: childValue,
          parentValues: [childItem.value],
        };
      });
    }
    return {
      ...data,
      value,
    };
  });
  return permissionTree;
};

// Get the difference between two array, return an array
export const arrayDifference = (array1, array2, key = "_id") => {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1[key] === object2[key];
    });
  });
};

// Compare two array of object and return true or false
export const compareArraysOfObject = (array1, array2, key = "_id") => {
  if (!Array.isArray(array1) && !Array.isArray(array2)) {
    return false;
  }
  const arrayDiff1 = arrayDifference(array1, array2, key);
  const arrayDiff2 = arrayDifference(array2, array1, key);
  return arrayDiff1.length === 0 && arrayDiff2.length === 0;
};

//set some object properties and create new one
export const combineObjectAndClone = (obj1 = {}, obj2 = {}) =>
  Object.assign({}, obj1, obj2);

//set some object properties but not create new one
export const combineObjectWithoutCloning = (obj1 = {}, obj2 = {}) => ({
  ...obj1,
  ...obj2,
});

export const downloadBlobFile = (data, filename) => {
  var blob = new Blob([data], { type: "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);
    tempLink.setAttribute("data-bcup-haslogintext", "no");

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.

    //tempLink.setAttribute('target', '_blank');
    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

export const getLinkInJSON = (node) => {
  if (node.type === "link" && node.url.match(REGEX_CHECK_LINKS)) {
    return node.url;
  }
  let link = "";
  if (Array.isArray(node.children)) {
    for (let deepChild of node.children) {
      const currentlink = getLinkInJSON(deepChild);
      if (currentlink) {
        link = currentlink;
        break;
      }
    }
  }
  return link;
};

export const getLink = (textToCheck) => {
  if (isJsonString(textToCheck)) {
    let parsedJNotes = JSON.parse(textToCheck);
    let children = parsedJNotes?.root?.children || [];
    for (let child of children) {
      if (Array.isArray(child.children)) {
        for (let deepChild of child.children) {
          if (
            deepChild.type === "link" &&
            REGEX_CHECK_LINKS.test(deepChild.url)
          ) {
            return deepChild.url;
          }
        }
      }
    }
  } else if (REGEX_CHECK_LINKS.test(textToCheck)) {
    return textToCheck.match(REGEX_CHECK_LINKS)?.[0];
  }
  return "";
};

export const getAllCandidatesInJobPipeline = (pipeline) => {
  let cards = [],
    columns = pipeline?.columns;
  if (typeof columns === "string") {
    columns = JSON.parse(columns);
  }
  const lanes = columns?.lanes;
  if (Array.isArray(lanes)) {
    cards = lanes
      .map((lane) =>
        lane.cards.map((card) => ({
          ...card,
          fullName: getCandidateFullName(card),
        })),
      )
      .flat();
  }
  return cards;
};

export const convertToNumericString = (value = "") => {
  const IS_COMMA_REGEX = /\,/g;
  let numericString = value.replace(IS_COMMA_REGEX, ".");

  numericString = numericString.replace(REGEX_NOT_NUMBER, "");

  const IS_DOT_REGEX = /\./g;
  if (numericString.match(IS_DOT_REGEX)?.length > 1) {
    return parseFloat(numericString).toString();
  }

  return numericString;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "Kb", "Mb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

// export const mapEmplacementToDevise = (emplacement) => {
//   return {
//     _id: emplacement?.currency?.substr(-4, 3),
//     name: emplacement?.currency?.substr(-4, 3)
//   };
// };
export const mapEmplacementToDevise = (emplacementsList) => [
  ...new Set(
    [
      ...new Set(
        emplacementsList.map((emplacement) =>
          emplacement?.currency?.substr(-4, 3),
        ),
      ),
    ]
      ?.sort()
      ?.map((empl) => ({ _id: empl, name: empl })),
  ),
];

export const getStatusColor = (status) =>
  statusColors[status] || "arh-bg-[#FFDF6B]";

export const isFileLocationExist = async (file = {}, onResponse = () => { }) => {
  try {
    const url = `${file.location}?cache_buster=${new Date().getTime()}`; // Éviter le cache
    const response = await fetch(url, { method: "GET", cache: "no-store" });

    const isSuccess = response.ok; // response.ok est vrai pour les statuts 200-299
    onResponse({ success: isSuccess, file, status: response.status });
  } catch (err) {
    onResponse({ success: false, file });
  }
};

export const $isEmptyTextNode = (textNode) => {
  const dataNode = parseJsonObject(textNode);
  const elNode = dataNode?.root?.children?.[0]?.children?.[0];
  if (elNode && typeof elNode?.text === "string" && !elNode.text?.trim()) {
    return true;
  }
  if (
    dataNode?.root?.children?.length === 1 &&
    dataNode.root.children[0]?.children?.length === 0
  ) {
    return true;
  }
  return false;
};

export const _getLastText = (str = "") => str.substr(str.lastIndexOf(" ") + 1);

export const _getAmountPerDistance = (distance, slices = []) => {
  if (!distance) return 0;
  let total = 0;
  let index = slices.findIndex(
    (_slice) => distance <= _slice.arrival && distance >= _slice.departure,
  );
  if (index === -1) index = slices.length - 1;
  total =
    Math.max(distance - slices[index].departure + 1, 0) * slices[index].amount;
  for (let k = index - 1; k >= 0; k--) {
    total += (slices[k].arrival - slices[k].departure + 1) * slices[k].amount;
  }
  return total.toFixed(2);
};

export const isImageOrVideoFile = (file) =>
  file?.mimetype?.match(/image|video/);

export const isThereImageOrVideo = (files = []) =>
  files.some(isImageOrVideoFile);

export const getImagesAndVideos = (files = []) =>
  files.filter(isImageOrVideoFile);

export function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export const getCityName = (autocomplete) => {
  return autocomplete?.city || autocomplete?.province || autocomplete?.country;
};

export const isSamePeriod = (periodA, periodB) =>
  moment(periodA?.startDate).isSame(periodB?.startDate, "days") &&
  moment(periodA?.endDate).isSame(periodB?.endDate, "days");

export const getInitPeriodFilter = (months = 3) => ({
  startDate: moment().startOf("day").toDate(),
  endDate: moment().add(months, "M").endOf("day").toDate(),
});

export const formatValidDate = (date, format = "DD/MM/YYYY") => {
  const dateMoment = moment(getDateToRender(date));

  if (dateMoment.isValid()) {
    return dateMoment.format(format);
  }

  return "-";
};
export const getCurrentMonthPeriod = () => ({
  startDate: moment().startOf("month").toDate(),
  endDate: moment().endOf("month").toDate(),
});

export const getLastThreeMonth = () => ({
  startDate: moment().subtract(3, "month").startOf("month").toDate(),
  endDate: moment().endOf("month").toDate(),
});

export const objToNamedList = (obj) => {
  const list = [];
  for (const attr in obj) {
    list.push({ _id: attr, name: obj[attr] });
  }
  return list;
};

export const assignValIdsObjOption = (data) => {
  const newObj = {};
  for (const elFilter in data) {
    if (!Array.isArray(data[elFilter]) && data[elFilter]._id) {
      newObj[elFilter] = data[elFilter]._id;
    }
    if (Array.isArray(data[elFilter])) {
      newObj[elFilter] = newObj[elFilter].map((el) => el._id);
    }
  }
  return newObj;
};

export const shave100Nbr = (note, dividedBy = 1) => {
  const result = note / dividedBy;

  if (Number.isInteger(result)) {
    return result;
  }

  return result.toFixed(2);
};

/**
 * @param { Number } num
 * @param { Number | null } dec
 * @returns { Number }
 */
export const toFixedIfDecimal = (num, dec = 2) =>
  Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);

export const convertObjToDropdownCfg = (config) => {
  let dropdownConfig = [];
  if (typeof config === "object") {
    for (const key in config) {
      dropdownConfig.push({ _id: key, value: config[key], name: config[key] });
    }
  }
  return dropdownConfig;
};

export const sortNews = (newsData) => {
  let newsSorted = [];
  if (Array.isArray(newsData)) {
    newsSorted = newsData
      .filter((news) => news.status !== "draft")
      .sort((newsA, newsB) => Boolean(newsB.pined) - Boolean(newsA.pined));
  }
  return newsSorted;
};

export const getParentFolderByKeyAndOriginalName = (keyWithOriginalName) => {
  let url = keyWithOriginalName?.key;
  if (Boolean(url)) {
    if (url.endsWith("/")) {
      url = url.slice(0, -1);
    }
    let parts = url.split("/");
    parts[parts.length - 1] = "";
    if (parts.length) parts = parts.join("/");
    return parts;
  } else {
    return "";
  }
};

export const parseStringToFloat = (value) => Number.parseFloat(value) || 0;

export const truncateString = (str, withTooltip = false) => {
  return (
    <div className=" arh-w-full arh-truncate arh-font-poppins arh-text-xs arh-font-medium arh-text-darkGreyColor">
      {withTooltip ? (
        <BlackTooltip title={str} placement="top"></BlackTooltip>
      ) : (
        str
      )}
    </div>
  );
};

export const getAmount = (reimbursement) => {
  let amount = reimbursement?.amount;
  if (
    reimbursement?.rate?._id &&
    ["accepted", "waiting"].includes(reimbursement?.status) &&
    reimbursement?.type === "normal"
  ) {
    const {
      tips = 0,
      costExcludingTaxes = 0,
      rate: { total = 0 },
    } = reimbursement;
    amount =
      Number(costExcludingTaxes) +
      Number(costExcludingTaxes) * parseFloat(total / 100) +
      Number(tips);
  }
  return Number(amount)?.toFixed(2);
};

export const getAmountBeforeRate = (reimbursment) => {
  if (reimbursment?.rate?._id) {
    if (
      reimbursment?.type === "normal" &&
      ["accepted", "waiting"].includes(reimbursment?.status)
    ) {
      const {
        costExcludingTaxes,
        rate: { total },
      } = reimbursment;
      return (
        Number(costExcludingTaxes) +
        Number(costExcludingTaxes) * parseFloat(total / 100)
      )?.toFixed(2);
    }
    return parseFloat(reimbursment?.amount).toFixed(2);
  }
  return 0;
};

export const handleCountAssignation = (workingHours) => {
  let assignation = 0;
  if (workingHours?.length) {
    for (const data of workingHours) {
      let employerAssignment = data?.EmployerAssignment;
      if (employerAssignment?.assignementTitle) {
        assignation++;
      }
    }
  }
  return assignation;
};

export const handleCountDetails = (workingHours) => {
  let details = [...workingHours];
  if (details.length) {
    details = details.filter((workingHour) => workingHour.detail);
  }
  return parseInt(details.length);
};

export const handleCountHoursTypes = (workingHours) => {
  let hoursTypes = [];
  if (workingHours?.length) {
    for (const data of workingHours) {
      let hourType = data?.WorkedHoursTypes?._id;
      if (hourType && !hoursTypes.includes(hourType)) hoursTypes.push(hourType);
    }
  }
  return hoursTypes.length;
};

export const sortArrayByAttrName = (arr, attrName) => {
  return arr.sort((a, b) => {
    if (
      typeof a[attrName] === "undefined" ||
      typeof b[attrName] === "undefined"
    ) {
      return 0;
    }
    const nameA = a[attrName].toUpperCase(); // ignore upper and lowercase
    const nameB = b[attrName].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
};

export const isValidObjectIds = (_ids) => {
  try {
    return Array.isArray(_ids)
      ? _ids.map((_id) => new mongoose.Types.ObjectId(_id))
      : new mongoose.Types.ObjectId(_ids);
  } catch {
    return false;
  }
};

// copied from employeFiles.config.js
export const makeDefaultOpener = (messageList) => {
  const currentMsg = localStorage.getItem("current-msg");
  if (messageList.includes(currentMsg)) {
    return true;
  }

  return false;
};

export const secondsToHoursFloat = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const minuteStr = minutes < 10 ? `0${minutes}` : minutes;
  return parseFloat(`${hours || 0}.${minuteStr || 0}`).toFixed(2);
};

export const getTaskSourceModel = (
  { _id, parentModel, Project } = {
    _id: null,
    parentModel: null,
    Project: null,
  },
) =>
  (Project && "Assignement") ||
  (parentModel && "Task") ||
  (_id === "formation" && "Formation") ||
  (_id && "Project") ||
  null;

export const convertStringToNestedProperty = (obj, str) => {
  // Split the input string into an array of keys using the dot as the separator
  const keys = str.split(".");
  // Initialize the result variable to the input object
  let result = obj;
  // Iterate through each key in the array
  for (const key of keys) {
    // Check if the property exists in the current result object
    if (result && result[key] !== undefined) {
      // If it exists, update the result to the value of the nested property
      result = result[key];
    } else {
      // If the property does not exist, set the result to undefined and break out of the loop
      result = undefined;
      break;
    }
  }
  // Return the final result, which represents the value of the nested property
  return result;
};

export const getMimeFileType = (fileType) => {
  switch (fileType) {
    case ".avi":
      return "video/x-msvideo";
    case ".mp4":
      return "video/mp4";
    case ".mkv":
      return "video/x-matroska";
    case ".mov":
      return "video/quicktime";
    case ".wmv":
      return "video/x-ms-wmv";
    case ".flv":
      return "video/x-flv";
    case ".webm":
      return "video/webm";
    case ".mpeg":
    case ".mpg":
      return "video/mpeg";
    case ".3gp":
      return "video/3gpp";
    case ".ogv":
      return "video/ogg";
    case ".m4v":
      return "video/x-m4v";
    case ".mts":
    case ".m2ts":
      return "video/MP2T";
    case ".ts":
      return "video/MP2T";
    case ".divx":
      return "video/divx";
    case ".xvid":
      return "video/xvid";
    default:
      return "";
  }
};

/**
 *
 * @param {string} dateA
 * @param {string} dateB
 * @param {"asc" | "desc"} sortOrder
 * @returns
 */
export function sortDate(dateA, dateB, sortOrder = "asc") {
  const dateMomentA = moment(dateA, "YYYY-MM-DD", true);
  const dateMomentB = moment(dateB, "YYYY-MM-DD", true);

  if (dateMomentA.isValid() && dateMomentB.isValid()) {
    if (sortOrder === "asc") {
      return dateMomentA.diff(dateMomentB);
    }

    return dateMomentB.diff(dateMomentA);
  }

  if (dateMomentA.isValid()) {
    return sortOrder === "asc" ? 1 : -1;
  }

  if (dateMomentB.isValid()) {
    return sortOrder === "asc" ? -1 : 1;
  }

  return 0;
}

export const customTooltips = (tooltipModel, chartRef, t) => {
  // don't forget to setup the legend in dataset
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML = "<div style='position:relative;'></div>";
    tooltipEl.style.pointerEvents = "none";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.transitionTimingFunction = "ease-out";
    tooltipEl.style.transitionDelay = "10ms";
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  const getBody = (bodyItem) => bodyItem.lines;

  // Set Text
  if (tooltipModel.body) {
    // const {xAlign} = tooltipModel
    let style =
      "position: absolute; top:-2px; left:35%; border-right: 10px solid transparent; border-left:10px solid transparent; border-bottom: 10px solid rgba(0,0,0,0.78)";

    // if(xAlign==="right") style = "position: absolute; top:30%; right:-2px; border-left: 10px solid rgba(0,0,0,0.78); border-top:10px solid transparent; border-bottom:10px solid transparent"
    const bodyLines = tooltipModel.body.map(getBody);
    const data = bodyLines[0][0]?.split(":");
    let innerHtml = `<div style="${style}"></div><div style="background: rgba(0, 0, 0, 0.78); z-index:2; border-radius:4px; padding:4px; color:white; font-family:Poppins;font-size:12px; display:flex;  flex-direction: column; border:1px solid transparent;"><div>${t(data[0].trim())}</div><div>${data[1]}</div></div>`;
    tooltipEl.innerHTML = innerHtml;
  }

  let position =
    chartRef?.current?.chartInstance?.canvas.getBoundingClientRect();
  // console.log({ position, chartRef })
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.zIndex = 60;
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left =
    10 + position.left + window.pageXOffset + tooltipModel.caretX + "px";
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY + "px";
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding =
    tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
  // tooltipEl.style.transitionTimingFunction = 'ease-in'
  // tooltipEl.style.transitionDelay = '10ms'

  tooltipEl.style.transform = "translate(-50%, 0)";
  tooltipEl.style.transition = "all .1s ease";
  tooltipEl.style.pointerEvents = "none";
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function flatNestedArrayOfObjects(arrayObjs, attrName) {
  var result = [];
  if (Array.isArray(arrayObjs) && arrayObjs?.length > 0) {
    arrayObjs.forEach(function (a) {
      result.push(a);
      if (Array.isArray(a[attrName]) && a[attrName].length > 0) {
        const childs = flatNestedArrayOfObjects(a[attrName], attrName);
        result = result.concat(childs);
      }
    });
  }
  return result;
}

export function removeGroupAncestorsWhenChildPresent(groupes) {
  const childs = groupes.filter(
    (group) => Array.isArray(group.ancestors) && group.ancestors.length > 0,
  );
  if (childs.length === 0) {
    return groupes;
  }
  const childAncestors = childs.reduce((ancestors, group) => {
    return ancestors.concat(group.ancestors);
  }, []);

  return groupes.filter((group) => !childAncestors.includes(group._id));
}

export function isGroupIdsMatchFilter(element, groupesFilter) {
  if (!Array.isArray(groupesFilter) || groupesFilter.length === 0) return true;
  if (!Array.isArray(element.groupIds) || element.groupIds.length === 0)
    return false;

  groupesFilter = removeGroupAncestorsWhenChildPresent(groupesFilter);

  const groupIdsIntersectAncestors = (groupIds, group) => {
    if (!Array.isArray(group.ancestors) || !Array.isArray(groupIds))
      return false;
    return group.ancestors.some((ancestorId) => groupIds.includes(ancestorId));
  };
  return groupesFilter.some(
    (groupFilter) =>
      element.groupIds.includes(groupFilter._id) ||
      groupIdsIntersectAncestors(element.groupIds, element),
  );
}

export function formatSelectedGroupsForFilter(selectedGroups) {
  if (!Array.isArray(selectedGroups)) {
    return [];
  }
  if (!selectedGroups?.[0]?._id) {
    return selectedGroups;
  }
  return selectedGroups.map((group) => group?._id);
}

export function formatGroupForInput(value) {
  return value;
}

export function formatGroupForFilter(groups) {
  if (!Array.isArray(groups)) return [];
  if (!groups?.[0]?._id) {
    return groups;
  }
  return groups.map((group) => group?._id);
}

export function getLinkLegende(text) {
  const linkRegex =
    /(https?:\/\/)[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w]*)?(\?[^\s]*)?/gi;
  const matches = text.match(linkRegex);
  const links = new Set();
  if (matches && matches.length > 0) {
    for (const match of matches) {
      try {
        new URL(match);
        links.add(match);
      } catch (error) {
        continue;
      }
    }
  }
  return Array.from(links);
}

export const checkErrors = (errorArray, type = "required") =>
  errorArray.some((err) => err.type === type);

export const applyFavIcon = (favIcon, reset = true) => {
  const cacheFavIcon = localStorage.getItem("favIcon");
  const links_icon = document.querySelectorAll("link[rel*='icon']");
  const image = document.querySelector(
    "link[rel*='apple-touch-startup-image']",
  );
  let links = [...links_icon, image];
  if (cacheFavIcon && !reset) {
    var { location, mimetype } = JSON.parse(cacheFavIcon);
  }
  if (favIcon) {
    if (reset) {
      localStorage.removeItem("favIcon");
    } else if (location !== favIcon.location) {
      localStorage.setItem(
        "favIcon",
        JSON.stringify({
          location: favIcon.location,
          mimetype: favIcon.mimetype,
        }),
      );
    }
    links.forEach((link) => {
      link.type = favIcon.mimetype;
      link.href = favIcon.location;
    });
  } else if (location) {
    links.forEach((link) => {
      link.type = mimetype;
      link.href = location;
    });
  }
};

export const clearStorage = () => {
  const cacheProjects = localStorage.getItem("projects");
  localStorage.clear();
  sessionStorage.clear();
  localStorage.setItem("projects", cacheProjects);
}